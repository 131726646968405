import React, { useState, useEffect } from "react"
import classNames from "classnames"


import styles from "./styles.module.scss"


interface Props {
	className?: string
	data: JSX.Element
}


function SeoText(props: Props) {
	const [activeText, setActiveText] = useState(false)

	return (
		<section className={styles.seotext}>
			<div className={classNames(
				styles.seotext__container,
				{[styles.seotext__container__active]: activeText}
			)}>
				{props.data}
			</div>
			<button
				onClick={() => setActiveText(!activeText)}
				type="button"
				className={styles.seotext__button}>
				{!activeText ? "Развернуть" : "Свернуть"}
			</button>
		</section>

	)
}

export default SeoText
