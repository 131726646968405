import React, { useState } from "react";
import { Navigation, Pagination, SwiperOptions } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import styles from "./styles.module.scss";
import "../../../styles/_V2/components/swiper.scss";
import ArrowLeftIcon from "../../../assets/_V2/svg/ArrowLeftIcon";
import ArrowRightIcon from "../../../assets/_V2/svg/ArrowRightIcon";

export interface AdvantagesItem {
	img?: string | JSX.Element;
	title: string;
	description: string | JSX.Element;
	descriptionClass?: string;
}

export enum ALIGNS {
	LEFT = "LEFT",
	CENTER = "CENTER",
}

interface Props {
	items: AdvantagesItem[];
	hideImages?: boolean;
	className?: string;
	breakpoints: {
		[width: number]: SwiperOptions
		[ratio: string]: SwiperOptions
	};
	align: ALIGNS;
}

export default function Advantages(props: Props) {
	const hideImages = props.hideImages !== undefined ? props.hideImages : true;
	const align = props.align;

	const breakpointsData = props.breakpoints || {
		0: {
			slidesPerView: 1,
			spaceBetween: 10,
			pagination: {
				clickable: true,
			},
		},
		// 400: {
		// 	autoHeight: false,
		// },
		768: {
			slidesPerView: 2,
		},
		1320: {
			slidesPerView: 3,
			spaceBetween: 0,
		},
	};

	const RenderItem = (item: AdvantagesItem, tabletHidden: boolean) => {
		return (
			<div
				key={item.title}
				className={classNames(
					styles.advantages__item,
					styles.advantagesItem,
					{ [styles.advantagesItemTabletHidden]: tabletHidden },
					{ [styles.advantagesItem_left]: align === ALIGNS.LEFT },
					{ [styles.advantagesItem_center]: align === ALIGNS.CENTER },
				)}
			>
				{item.img && !hideImages && (
					<figure className={styles.advantagesItem__img}>{item.img}</figure>
				)}

				{typeof item.description === "string" ? (
					<h3
						className={styles.advantagesItem__title}
						dangerouslySetInnerHTML={{ __html: item.title }}
					/>
				) : (
					<h3
						className={styles.advantagesItem__title}
					>
						{item.title}
					</h3>
				)}

				{typeof item.description === "string" ? (
					<p
						className={classNames(styles.advantagesItem__description, item?.descriptionClass)}
						dangerouslySetInnerHTML={{ __html: item.description }}
					/>
				) : (
					<p className={classNames(styles.advantagesItem__description, item?.descriptionClass)}>
						{item.description}
					</p>
				)}
			</div>
		);
	};

	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);

	const slideChange = slider => {
		setIsBeginning(slider.isBeginning);
		setIsEnd(slider.isEnd);
	};

	return (
		<section className={classNames(styles.advantages, props.className)}>
			<Swiper
				className={styles.advantages__slider}
				modules={[Navigation, Pagination]}
				breakpoints={breakpointsData}
				pagination={{
					clickable: true,
					clickableClass: styles.swiperPagination,
				}}
				navigation={{
					nextEl: ".next",
					prevEl: ".prev",
				}}
				autoHeight={false}
				onSlideChange={slideChange}
			>
				{props.items.map(item => (
					<SwiperSlide key={item.title} className={styles.swiperSlide}>
						{({ isActive, isNext }) => RenderItem(item, !isActive && !isNext)}
					</SwiperSlide>
				))}

				<button
					type="button"
					className={classNames(
						styles.navigationButton,
						styles.navigationButtonLeft,
						{ [styles.navigationButtonHidden]: isBeginning },
						"prev",
					)}
				>
					<ArrowLeftIcon />
				</button>

				<button
					type="button"
					className={classNames(
						styles.navigationButton,
						styles.navigationButtonRight,
						{ [styles.navigationButtonHidden]: isEnd },
						"next",
					)}
				>
					<ArrowRightIcon />
				</button>
			</Swiper>
		</section>
	);
}
