import { Data } from "../../../components/_V2/BusinessTypes/types"
import { getBusinessTypes } from "./business-types"
import { Locales } from "../../../localization/types";

export const businessTypes = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			...getBusinessTypes(locale),
			type: "compact",
			theme: "grayLight",
		}
	}

	if (locale === 'ru-BY') {
		return {
			...getBusinessTypes(locale),
			type: "compact",
			theme: "grayLight",
		}
	}

	return {
		...getBusinessTypes(locale),
		type: "compact",
		theme: "grayLight",
	}
}
