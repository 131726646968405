import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'

import CtaForm from '../pagesSections/index/CtaForm'

import Presentation from '../pagesSections/intergations/ProductPresentation/assets/intergations-presentation.png'
import PresentationKz from '../pagesSections/intergations/ProductPresentation/assets/intergations-presentation-kz.png'
import PresentationBy from '../pagesSections/intergations/ProductPresentation/assets/intergations-presentation-by.png'
import IntegrationsProductPresentation from '../pagesSections/intergations/ProductPresentation'
import AccountingFeatures from '../pagesSections/intergations/AccountingFeatures'
import ProductsFeatures from '../pagesSections/intergations/ProductsFeatures'
import DeliveryFeatures from '../pagesSections/intergations/DeliveryFeatures'
import YandexFeatures from '../pagesSections/intergations/YandexFeatures'
import CardFeatures from '../pagesSections/intergations/CardFeatures'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'

import { getAdvantagesSlides } from '../pages-data/_V2/integrations/advantages'
import styles from './styles/_V2/integrations.module.scss'
import Advantages from '../components/_V2/Advantages'
import BusinessTypes from '../components/_V2/BusinessTypes'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import { externalLinks } from '../pages-data/_V2/common/links'

import { useLocalizationContext } from '../localization/useLocalizationContext'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import { getSeoText } from "../pages-data/_V2/integrations/seotext";
import SeoText from "../components/_V2/SeoText";

export default function IntegrationsPage() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Интеграции с дополнительными сервисами для бизнеса | Quick Resto',
    'ru-KZ': 'Интеграции с дополнительными сервисами для бизнеса | Quick Resto Казахстан',
    'kz-KZ': 'Интеграции с дополнительными сервисами для бизнеса | Quick Resto Казахстан',
    'ru-BY': 'Интеграции с дополнительными сервисами для бизнеса | Quick Resto Беларусь',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}integrations/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}integrations/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}integrations/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}integrations/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Интеграция системы Quick Resto с сервисами для бизнеса: 1С, ЕГАИС, Эвотор, Меркурий, Яндекс.Еда, СберМаркет',
    'ru-KZ': 'Интеграции с дополнительными сервисами для бизнеса → Двусторонний обмен документами с 1С → Облачный документооборот с поставщиками продуктов → Больше заказов при работе с агрегаторами доставки. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Интеграции с дополнительными сервисами для бизнеса → Двусторонний обмен документами с 1С → Облачный документооборот с поставщиками продуктов → Больше заказов при работе с агрегаторами доставки. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Интеграции с дополнительными сервисами для бизнеса → Двусторонний обмен документами с 1С → Облачный документооборот с поставщиками продуктов → Больше заказов при работе с агрегаторами доставки. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Presentation}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresentationBy}`,
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:url',
      content: currentCanonicalLink,
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale]
    },
  ]

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={currentTitle}
      metaTags={metaTags}
      linkCanonical={currentCanonicalLink}
      footerClassName={pageStyles.pageSection}
    >
      <IntegrationsProductPresentation />

      <Advantages
        items={getAdvantagesSlides(localizationContext.locale)}
        className={classNames(
				  styles.integrations__advantages,
				  pageStyles.pageSection,
        )}
      />

      <AccountingFeatures className={pageStyles.pageSection} />

      <ProductsFeatures className={pageStyles.pageSection} />

      {localizationContext.locale === 'ru-RU' && (
      <YandexFeatures className={pageStyles.pageSection} />
          )}
      <DeliveryFeatures className={pageStyles.pageSection} />

      <CardFeatures className={pageStyles.pageSection} />

      {
        localizationContext.locale !== 'ru-RU'
        && (
        <AdditionalServices
          className={pageStyles.pageSection}
        />
        )
      }

      <CtaForm isFry={false} />

      <BusinessTypes
        key={localizationContext.locale}
        data={businessTypes(localizationContext.locale)}
        className={classNames(
				  styles.integrations__types,
				  pageStyles.pageSection,
        )}
      />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
      <SeoText
          className={pageStyles.pageSection}
          data={getSeoText()}
      />
    </DefaultLayout>
  )
}
