import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types";
import classNames from "classnames";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			pageName: "Интеграции",
			desktopTitle: "Умножай свои возможности",
			subTitle: (
				<p className={styles.description}>
					Подключи дополнительные сервисы и&nbsp;получи больше возможностей для
					бизнеса. Все интеграции выполняются без привлечения разработчиков.
				</p>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails: (
				<>
					Быстро, удобно и две недели бесплатно
				</>
			),
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={classNames(styles.image, styles.imageKz)}
					alt="управление персоналов в ресторане"
					src="./assets/intergations-presentation-kz.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}

	}
	if (locale === 'kz-KZ') {
		return {
			pageName: "Интеграциялар",
			desktopTitle: "Өз мүмкіндіктеріңізді көбейтіңіз",
			subTitle: (
				<p className={styles.description}>
					Қосымша сервистерді қосыңыз және бизнес үшін көбірек мүмкіндіктер алыңыз. Барлық интеграциялар әзірлеушілерді тартпай орындалады.
				</p>
			),
			cta: pagesLinksKz.registration.text,
			desktopCtaDetails: (
				<>
					Тез, ыңғайлы және екі апта тегін
				</>
			),
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={classNames(styles.image, styles.imageKz)}
					alt="управление персоналов в ресторане"
					src="./assets/intergations-presentation-kz.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}

	}
	if (locale === 'ru-BY') {
		return {
			pageName: "Интеграции",
			desktopTitle: "Расширяй свои возможности",
			subTitle: (
				<p className={styles.description}>
					Подключи дополнительные сервисы и&nbsp;получи больше возможностей для
					бизнеса. Все интеграции выполняются без привлечения разработчиков.
				</p>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails: (
				<>
					Быстро, удобно и две недели бесплатно
				</>
			),
			ctaLink: pagesLinks.registration,
			figure: (
				<>
					<StaticImage
						className={classNames(styles.image, styles.imageDesktop, styles.imageBy)}
						alt="управление персоналов в ресторане"
						src="./assets/intergations-presentation-by.png"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={classNames(styles.image, styles.imageMobile, styles.imageBy)}
						alt="управление персоналов в ресторане"
						src="./assets/intergations-presentation-mobile-by.png"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</>

			),
		}
	}

	return {
		pageName: "Интеграции для автоматизации ресторана",
		desktopTitle: "Ещё больше возможностей",
		subTitle: (
			<p className={styles.description}>
				Подключи дополнительные сервисы и&nbsp;получи больше возможностей для
				бизнеса. Все интеграции выполняются без привлечения разработчиков.
			</p>
		),
		cta: pagesLinks.registration.text,
		desktopCtaDetails: (
			<>
				За&nbsp;10&nbsp;минут, на&nbsp;любом оборудовании, с&nbsp;удобным
				интерфейсом. И&nbsp;две недели&nbsp;бесплатно.
			</>
		),
		ctaLink: pagesLinks.registration,
		figure: (
			<StaticImage
				className={styles.image}
				alt="управление персоналов в ресторане"
				src="./assets/integrations-new.png"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	}
}
