import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg"

import mediaContent from "./mediaContent.module.scss"
import styles from "./styles.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import classNames from "classnames"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "",
					desktopTitle: "Карты лояльности без пластика",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Карты создаются системными приложениями Apple Wallet и&nbsp;Google
							Pay. Все данные гостей синхронизируются с&nbsp;CRM системой Quick
							Resto.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/integrations-card-2.png"
							alt={"электронные карты лояльности"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
					detailLink: pagesLinks.passkit,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "",
					desktopTitle: "Пластиксіз адалдық карталары",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Карталарды Apple Wallet және Google Pay жүйелік қосымшалары жасайды.
							Қонақтардың барлық деректері Quick Resto CRM жүйесімен синхрондалады.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/integrations-card-2.png"
							alt={"электронные карты лояльности"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
					detailLink: pagesLinks.passkit,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Чаевые",
					desktopTitle: <>Чаевые по&nbsp;карте</>,
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Гостей мотивирует поблагодарить официанта,
							а&nbsp;сотрудника — улыбаться им шире. Интеграция
							с&nbsp;электронными чаевыми «Нетмонет» и «CloudTips».
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/integrations-card-1.png"
							alt={" электронные чаевые"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
					bottomContent: (
						<div className={styles.notes}>
							<a
								href="https://netmonet.co/partnership?promocode=qresto"
								className={classNames(styles.note, styles.link)}
								target="_blank"
							>
								<StaticImage
									src="../../../assets/images/netmonet.png"
									alt=""
									objectFit="contain"
									placeholder="blurred"
									quality={90}
								/>
							</a>
							<a
								href="https://cloudtips.ru/business?utm_source=partners&utm_medium=quickresto"
								className={classNames(styles.note, styles.link)}
								target="_blank"
							>
								<StaticImage
									src="../../../assets/images/cloudtips.png"
									alt=""
									objectFit="contain"
									placeholder="blurred"
									quality={90}
								/>
							</a>
						</div>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Карты лояльности",
					desktopTitle: "Моментальная карта",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Карты создаются системными приложениями Apple Wallet и&nbsp;Google
							Pay. Все данные гостей синхронизируются с&nbsp;CRM системой Quick
							Resto.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/integrations-card-2.png"
							alt={"электронные карты лояльности"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
					detailLink: pagesLinks.passkit,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 490,
				tabName: "Чаевые",
				desktopTitle: <>Чаевые по&nbsp;карте</>,
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Возможность гостям поблагодарить официанта,
						а&nbsp;сотруднику&nbsp;&mdash; улыбаться им&nbsp;шире. Интеграция
						с&nbsp;электронными чаевыми &laquo;Нетмонет&raquo; и&nbsp;&laquo;CloudTips&raquo;.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image1}
						src="./assets/integrations-card-1.png"
						alt={" электронные чаевые"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop} />,
				bottomContent: (
					<div className={styles.notes}>
						<a
							href="https://netmonet.co/partnership?promocode=qresto"
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<StaticImage
								src="../../../assets/images/netmonet.png"
								alt=""
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</a>
						<a
							href="https://cloudtips.ru/business?utm_source=partners&utm_medium=quickresto"
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<StaticImage
								src="../../../assets/images/cloudtips.png"
								alt=""
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</a>
					</div>
				),
			},
			{
				desktopContentWidth: 490,
				tabName: "Карты лояльности",
				desktopTitle: "Мгновенный выпуск карты",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Карты создаются системными приложениями Apple Wallet и&nbsp;Google
						Pay. Все данные гостей синхронизируются с&nbsp;CRM системой Quick
						Resto.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image2}
						src="./assets/integrations-card-2.png"
						alt={"электронные карты лояльности"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop} />,
				detailLink: pagesLinks.passkit,
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	}
}
