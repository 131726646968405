import React from "react"
import {Locales} from "../../../localization/types";

export const getAdvantagesSlides = (locale :Locales) => {
	if (locale === 'kz-KZ') {
		return [
			{
				title: "Жылдам бастау",
				description:
					"Standard және Pro тарифтерінде бизнеске арналған негізгі сервистермен біріктіру",
			},
			{
				title: "Әзірлеушілерсіз",
				description:
					"Әп-сәтте жылдам қосылу. Бірақ егер талап етілсе, техникалық қолдай жаныңызда болады.",
			},
			{
				title: "Дайын шешімдер",
				description:
					"Барлығы масштабтау үшін: дайын сату арналары және тексерілген серіктестер ұсынған цифрлық сервистер.",
			},
		]
	}

	return [
		{
			title: "Быстрый старт",
			description:
				"Интеграция с&nbsp;основными сервисами для бизнеса на&nbsp;тарифах Standard и&nbsp;Pro.",
		},
		{
			title: "Без разработчиков",
			description:
				"Простое подключение в&nbsp;два счета. Но&nbsp;если потребуется, техподдержка будет рядом.",
		},
		{
			title: "Готовые решения",
			description:
				"Всё для масштабирования: готовые каналы продаж и&nbsp;цифровые сервисы от&nbsp;проверенных партнёров.",
		},
	]
}

export default [
	{
		title: "Быстрый старт",
		description:
			"Интеграция с&nbsp;основными сервисами для бизнеса на&nbsp;тарифах Standard и&nbsp;Pro.",
	},
	{
		title: "Без разработчиков",
		description:
			"Простое подключение в&nbsp;два счета. Но&nbsp;если потребуется, техподдержка будет рядом.",
	},
	{
		title: "Готовые решения",
		description:
			"Всё для масштабирования: готовые каналы продаж и&nbsp;цифровые сервисы от&nbsp;проверенных партнёров.",
	},
]
